@import "../../../theme/mixins.scss";
@mixin columdirection(){
    .quiz-box{
         flex-direction: column;
         row-gap: 10px;
        .img{
            max-width:100% !important;
        }
        .question-box{
           max-width: 100% !important;
        }
    }
 }


.quizzes-continuer{
    .MuiTypography-root{
        // kim's request by default font size 20px
        font-size: 20px;
    }
    .paper-data {
        padding: 25px 35px 30px 35px;
        background: #ffff;
        border-radius: 5px;
        width: 100%;
        box-shadow: 0 2px 16px 4px rgba(40, 44, 63, 0.05);
        @include for-ipad-pro-devices {
            @include columdirection();
         }
        .true_false{ text-transform:capitalize !important; }
        .quiz-box{
            display: flex;
            column-gap: 20px;
            .img{
                max-width:35%;
                align-items: flex-start;
                justify-content: center;
                display: flex;
                padding:10px;
                min-width: 30%;
                img{width: 100%;}
            }
            .question-box{
                width: 70%;
            }
        }
        .ck-check-box{
            .Mui-checked{color: var(--primary-color-1) !important;}
            .MuiButtonBase-root{svg{font-size: 24px;}}
        }

        @include for-tablet-up {
            margin: 0;
        }
        .score-board{
            overflow: hidden;
            margin: -25px -35px -30px;
            .vt-btn{
                display: flex;
                justify-content: space-between;
                justify-content: end;
                column-gap: 16px;
                padding: 16px;
                padding-top: 10px;
                // Button{
                //     background-color: var(--primary-color-2) ;
                //     color: #000;
                //     min-width: 90px;
                //     cursor: pointer;
                //     border: 1px solid var(--primary-color-2) ;
                //     min-height: 35px;
                //     border-radius: 5px;
                //     padding: 0 8px;
                //     &:hover{
                //         background-color: #fff !important;
                //     }
                // }
            }
        }

        .title {
            padding: 4px 4px 8px 1px;
            color: var(--color-black-3);
            font-family: var(--global-font-family);
            // text-align: justify;
            font-size: 20px;
            line-height:var(--global-line-height);
        }

        .pagination_Numbers {
            float: left;
            margin-left: -10px;
            @include for-laptop-up{
                width: 50%;
                display: flex;
                flex-wrap: wrap;
                justify-self: end;
                margin-left: 2px;
            }
            .Mui-selected,
            .css-1to7aaw-MuiButtonBase-root-MuiPaginationItem-root:hover {
                border-radius: 50px;
            }
        }
        .group-selection{
            padding: 11px;
        }
        .bottom{
            display: flex;
            align-items: flex-end;
            justify-content: space-between;
        }

        .buttons {
            // justify-content: flex-end;
            display: flex;
            margin-top: 30px;
            @mixin btn {
                padding: 5px 16px;
                border-radius: 5px;
            }
            button{
                margin:0 4px;
            }
            // .backbutton {
            //     @include btn;
            //     margin-right: 20px;
            //     background-color: var(--secondary-color-1);
            //     color: #fff !important;
            //     cursor: pointer;
            // }

            // .nextbutton {
            //     @include btn;
            //     background-color: var(--primary-color-2) !important;
            //     color: #000 !important;
            //     cursor: pointer;
            //     &:hover {
            //         background-color: var(--primary-color-2) !important;
            //         font-weight: bold;
            //     }
            // }
        }

        .complete_text {
            padding-bottom: 100px;
        }

        .response-main {
            background: #f8f8f8;
            padding: 10px;
            margin-top: 15px;
            border-radius: 10px;
        }

        .radio_multi {
            margin-top: 0px !important;
            padding: 0px !important;
            display: flex;
            align-items: flex-start;
            .MuiCheckbox-root{margin-top: 5px;}
        }

        .radio {
            margin-right: 7px !important;
            padding: 0px !important;
            &.MuiRadio-root{ margin-top: 5px !important;}
        }
        .radio_multi {
            .css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked {
                color:var(--primary-color-1) !important;
            }
        }
        .pagination {
            .Mui-selected {
                background-color: var(--primary-color-1);
            }
        }
        .img_congratualte{
            display: flex;
            justify-content: center;
            padding: 30px 30px 0 30px;
        }
        .confetti{
            position: relative;
        }
        .Text_congratulate{
            margin-left: 30px;
            margin-top: 5px;
            @media (max-width: 600px) {
                margin-left: 0px;
            }
            .font_congratulate{
                font-size:var(--global-font-size);
                font-weight: 900;
            }
            .font_congratulate_1{
                font-size: 20px;
                font-weight: 900;
            }
            .font_congratulate_2{
                font-size: 20px;
                font-weight: 400;
            }
            .font_congratulate_3{
                font-size: 20px;
                font-weight: 400;
            }
            .font_congratulate_4{
                font-size: 20px;
                font-weight: 400;
            }
        }
    }
}
._mobile{
    .paper-data .pagination_Numbers {
        width: 50%;
        display: flex;
        flex-wrap: wrap;
        justify-content: end !important;
    }
}
// this one used for KnowledgeCheck also
.paper-data .feed-back{
    background: #f8f8f8;
    padding: 10px;
    margin-top: 15px;
    border-radius: 10px;
    .inner-box{ 
        display: flex;
        svg{
            margin-right: 10px;
            font-size:30px !important;
            &.wrong{color:var(--bs-danger);}
            &.correct{color: var(--primary-color-1) !important;}
        }
        .feedback_text{
            font-size:20px;
            line-height: var(--global-line-height);
        }
    }
}
._tablet,._mobile{.paper-data  {@include columdirection();}  }