@import "../../theme/mixins.scss";

.header {
  position: absolute;
  width: 100%;
  height: var(--header-height);
  background-color: var(--color-white);
  display: flex;
  align-items: center;
  z-index: 99999999;
  font-family:var(--global-font-family);
  
}

.header-div {
  padding-top: 0 !important;
  h1{
    @include for-desktop-medium{
      font-size: 26px;
    }
    @include for-phone-only {
      font-size: 23px;
   }
  }
}

.skip-section {
  min-height: unset !important;
  border-bottom: unset;
  padding-top: 0;
  p {
    margin: 0 150px;
    text-align: center;
    padding: 15px 0px;
    cursor: pointer;
    @include for-tablet-up {
      margin: 0 30px;
    }
  }
}

.prev-section {
  p {
    .arrow-prev {
      padding: 0;
      display: block;
    }
  }
  background: #f8f8f8;
}

span.highlight-device,
span.highlight-component,
button.highlight-help,
button.highlight-template {
  color: var(--primary-color-1) !important;
}

button.highlight-profile svg {
  color: var(--primary-color-1) !important;
}

.next-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 80px;
  margin-left: 55px;
  .end-txt {
    color: #ffff;
  }
  button{
    // padding: 10px 28px !important;
    margin-bottom: 20px;
    // min-width: 123px;
  }

  // button {
  //   background: var(--primary-color-2);
  //   color: #000 !important;
  //   border-radius: 7px;
  //   padding: 13px 20px;
  //   min-width: 120px;
  //   font-weight: normal;
  //   border: none;
  //   margin-bottom: 20px;
  //   text-transform: uppercase;
  //   .next-txt {
  //     padding: 0;
  //     border: 0;
  //     margin: 0;
  //   }
  //   margin-bottom: 20px;
  // }
  // .btn-prev {
  //   background-color: var(--primary-color-2) !important;
  //   color: #000 !important;
  // }
  // .disabled-btn {
  //   background-color: var(--secondary-color-1) !important;
  //   color: #fff !important;
  // }
}

.image-cards.scrollable-section {
  h2 {
    margin: 2rem 10%;
  }
}

.imag-text-card {
  padding-bottom: 50px;
  border-bottom: 1px solid #cccc;
  margin: 0;
  .custom-card {
    padding: 5px 0;
    margin-bottom: 0;
  }
}

.video-player-secondary {
  margin: 0 5%;
  .video-container {
    margin-top: 50px;
  }
}

.tab-player {
  margin: 0 15%;
}

div.vjs-poster {
  background-size: cover;
}

.quote-txt-section {
  background: #f5f5f5;
  padding: 42px 100px;
  margin-top: 50px;
  .quote-border {
    border-top: 4px solid var(--primary-color-1);
    width: 10%;
  }
  h3 {
    line-height: 35px;
  }
}

.course-info {
  margin: 0 5%;
  .intro-course,
  .info-list-snippet {
    margin: 0 10%;
  }
  .info-snippet {
    margin: 40px 10%;
    border-bottom: 1px solid #cccc;
    font-weight: 400;
    color: #585656;
    font-size: 12px;
    p {
      margin-bottom: 2rem;
    }
  }
  .audio-main {
    margin: 0 15%;
  }
  .info-list-snippet {
    margin: 40px 10%;
    border-bottom: 1px solid #cccc;
    padding-bottom: 50px;
    .snippet {
      line-height: 30px;
      font-size: 16px;
      margin-top: 7rem;
      margin-bottom: 2rem;
    }
  }
  div.video-container {
    margin-top: 25px;
  }

  .image-cards {
    h2 {
      margin: 2rem 10%;
    }
  }

  .flip-card-view {
    place-content: center;
  }

  .card-flip-info {
    margin: 0 10%;
    h2 {
      margin-bottom: 40px;
    }
  }

  div.flipcard-main-container {
    margin: 0;
    width: 100%;
    padding: 15px 0;
  }

  .course-info-list {
    list-style: initial;
    line-height: 30px;
    font-size: 16px;
  }

  nav.hidenav {
    display: none;
  }
}

.hideText {
  display: none !important;
  transition: display 0.3s ease-in-out;
}

.sidebar::-webkit-scrollbar {
  width: 8px;
}

/* Track */
.sidebar::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.sidebar::-webkit-scrollbar-thumb {
  background: #c5c5c5;
  border-radius: 10px;
}
// Course Structure linear Sidebar
.crs-linear-container{
  // .expand-tree-icon {
  //   left: 14%;
  // }
  // .sidebar.open {
  //   width: 14%;
  // }
  // .content.open {
  //   left: 14%;
  // }
  @include for-desktop-medium   {
    .expand-tree-icon {
      left: 20%;
    }
    .sidebar.open {
      width: 20%;
    }
    .content.open {
      left: 20%;
    }
  }
@include for-laptop-medium-up{
  .sidebar.open {
    width: 60% !important;
  }
}


  .expand-sideBar{
   .tp-text-area{
    max-width: 100% !important;
   }
  }
  .collapsed-sideBar{
    .lesson-progress{display: none !important;}
  }
}
.expand-tree-icon {
  position: fixed;
  z-index: 999;
  display: none;
  top: 55%;
  // left: 20%;
  @include switch-direction-rtl(left,20%);
  transition: left 0.3s ease-in-out;
  background: #ffffff;
  padding: 10px 10px 10px 1px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  cursor: pointer;
  animation: expand 0.5s ease-in-out;
  @include for-ipad-pro-devices {
    // left: 29%;
    @include switch-direction-rtl(left,29%);
    display: none !important;
  }
  @include for-tablet-up {
    // left: 39%;
    @include switch-direction-rtl(left,39%);
  }
  @include for-mobile-medium-large {
    // left: 50%;
    @include switch-direction-rtl(left,50%);
    z-index: 9;
  }
  @keyframes expand {
    0% {
      transform: scale(0);
      opacity: 0;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
  &.show-expand-icon {
    display: block;
  }
  &.expand-tree-big {
    left: 40%;
    // @include switch-direction-rtl(left,40%);
    
    @include for-ipad-pro-devices {
      left: 59% ;
      // @include switch-direction-rtl(left,59%); 
    }
    @include for-tablet-up {
      left: 95% ;
      // @include switch-direction-rtl(left,95%); 
    }
  }
}


.collapsed-sideBar {
  .list-item {
    .deeper-child::before {
      // left: 38px;
      @include switch-direction-rtl(left,38px);
    }
  }
}

.sidebar {
  // position: absolute;
  position: fixed;
  top: 0;
  padding-top: 70px;
  // left: 0;
  @include switch-direction-rtl(left,0);
  max-width: 90px;
  height: 100%;
  width: 90px;
  transition: left 0.3s ease-in-out, width 0.3s ease-in-out;
  background-color: var(--color-white);
  // overflow-y: auto;
  // overflow-x: hidden;
  border-right: 1px solid var(--border-color);
  background-color: var(--color-white);
  box-shadow: var(--box-shadow-2);
  overflow-x: hidden;
  overflow-y: auto;
  .top-note-icon {
    padding-left: 28px !important;
  }
  .title-topics {
    padding-bottom: 15px;
    padding-top: 15px;
    background: #f1f1f1;
    border-left: 4px solid var(--primary-color-1);
  }
  //   .list-item {
  //     .deeper-child {
  //       &:after {
  //         display: none;
  //       }
  //       .tp-info:before {
  //         display: none;
  //       }
  //     ul.cus-tree-list {
  //         &:after {
  //           display: none
  //         }
  //     .deeper-most-child {
  //       .vertical-border {
  //         display: none;
  //       }
  //         &:before {
  //           left: 37px;
  //         }
  //     }
  //   }
  //  }
  //  &.deeper-child {
  //   &::before {
  //     left: 37px
  //   }
  // }
  // &.deeper-most-child {
  //   &::before {
  //     left: 38px
  //   }
  // }
  // }

  ul.cus-tree-list {
    &:after {
      display: none;
    }
    .deeper-most-child {
      .vertical-border {
        display: none;
      }
      &:before {
        // left: 40px;
        @include switch-direction-rtl(left,40px);
      }

      &:after {
        display: none;
      }

      // .tp-info {
      //   &:before {
      //     display: none;
      //   }
      // }
    }
  }

  .circular-progress {
    display: none;
  }

  .home-icon {
    margin-top: 15px;
    padding-bottom: 10px;
    svg {
      color: #a8a3a3;
      font-size: 30px;
    }
  }

  .main-line.vertical-border {
    display: none;
  }

  .list-item.level5,
  .list-item.level7 {
    display: none;
  }

  .list-item {
    padding-left: 0;
    .circle-icon {
      margin-left: 33px;
    }
  }

  .list-item.active-item {
    padding-left: 0;
    // .circle-icon {
    //   margin-left: 29px;
    // }
  }
  .accordion {
    .vertical-border {
      // left: 43px;
      @include switch-direction-rtl(left, 43px);
    }
    .acc-node {
      padding-left: 33px;
    }
  }

}

.completion-modal_inner{
  @include  for-desktop-up{min-height: 210px;}
}

.content {
  position: fixed;
  overflow-y: auto;
  overflow-x: hidden;
  // left: 80px;
  @include switch-direction-rtl(left, 80px);
  // right: 0;
  @include switch-direction-rtl(right,0);
  bottom: 0;
  height: 100%;
  transition: left 0.3s ease-in-out, width 0.3s ease-in-out, max-width 0.3s ease-in-out;
  // background-color: rgb(118, 177, 51);
  font-family: var(--global-font-family) !important;
  background: #f8f8f8;
  @include for-ipad-pro-devices {
    // left: 0;
    @include switch-direction-rtl(left, 0);
  }
}
.editpanel-space {
  // right: calc(100% - 78%) !important;
  @include switch-direction-rtl(right,calc(100% - 78%) !important);
}
.attest-ck-check-box{
  font-size: 14px !important;
  svg{
    font-size: 20px !important;
  }
}

.sidebar.open {
  left: 0;
  // @include switch-direction-rtl(left, 0);
  width: 20%;
  max-width: unset;
  z-index: 9;
  .top-note-icon {
    padding-left: 32px !important;
  }
  .title-topics {
    padding-bottom: 0;
    padding-top: 0;
    background: inherit;
    border-left: 0;
  }
  .circular-progress {
    display: initial;
  }
  .list-item.level5,
  .list-item.level7 {
    display: block;
  }
  .main-line.vertical-border {
    display: block;
  }
  @include for-ipad-pro-devices {
    width: 30%;
  }
  @include for-tablet-up {
    width: 40%;
  }
  @include for-mobile-medium-large {
    width: 70%;
    z-index: 9;
  }
  .list-item {
    .deeper-child {
      &:after {
        display: initial;
      }
      .tp-info:before {
        display: initial;
      }
      ul.cus-tree-list {
        &:after {
          display: initial;
        }
        .deeper-most-child {
          &:before {
            display: initial;
            // left: 72px;
            @include switch-direction-rtl(left, 72px);
          }
        }
      }
    }
  }

  ul.cus-tree-list {
    &:after {
      display: initial;
    }
    .deeper-most-child {
      &:before {
        display: initial;
        // left: 65px;
        @include switch-direction-rtl(left, 65px);
      }
      &:after {
        display: initial;
      }

      .tp-info {
        &:before {
          display: initial;
        }
      }
    }
  }
  .home-icon {
    display: flex;
    padding: 20px 0 0 0;
    margin-top: 5px;
    p {
      flex: 0 0 90%;
      padding: 10px 4px 0px 10px;
      color: #a8a3a3;
    }
    span {
      svg {
        font-size: 30px;
        color: #a8a3a3;
      }
    }
  }
  &.expand-sideBar {
    width: 40% !important;
    @include for-ipad-pro-devices {
      width: 60%!important;
    }
    @include for-tablet-up {
      width: 95% !important;
    }
  }
}
//sweet-alert course completed
.course-completed-success{
   .sweet-alert{
    div:first-child {
      width: 80px !important;
      height: 80px !important;
    }
    .success-message{
      h2,.sub-success{font-weight:600;}
    }
   }
}
.left-to-right{
  .content.open{
    left: 20%;
  }
}
// .sidebar.open + .content {
//   left: 0
// }
.content.open {
  // // left: 20%;
  // @include switch-direction-rtl(left,20%);
  @include for-ipad-pro-devices {
    // left: 30%;
    @include switch-direction-rtl(left, 30%);
    width: 100%;
  }
  @include for-tablet-up {
    // left: 40%;
    @include switch-direction-rtl(left,  40%);
    width: 100%;
  }
  @include for-mobile-medium-large {
    // left: 0;
    @include switch-direction-rtl(left,  0);
    width: 100%;
  }
}
.nav-icon {
  cursor: pointer;
  margin-left: 10px;
}
.home-icon {
  margin-left: 30px;
  cursor: pointer;
}

.hidden {
  display: none;
}
.comp-layout {
  max-width: 1600px;
  margin: 0 auto;
  // border: rgb(139, 46, 103) 1px solid;
}

.home-route {
  animation-name: make_play_transition;
  animation-duration: 1.8s;
}

.list-div {
  @include for-tablet-up {
    margin: 0;
  }
}

@keyframes make_play_transition {
  from {
    width: 20%;
  }
  to {
    width: 100%;
  }
}

.loader-info {
  font-size: 1.8rem;
  padding-top: 10px;
  padding-left: 10px;
}

._mobile {
  .cardflip-main-container {
    .col-sm-12 {
      width: 100%;
    }
  }
  .main-layout-container{
    margin-top: 100px !important;
  }
  .img-selection {
    .list-items {
      flex-direction: column;
      li {
        width: 90%;
      }
    }
  }

  .flipcard-main-container {
    .col-sm-12 {
      width: 100%;
    }
  }
  //heading Topic font
  .header-div {h1{font-size: 26px !important;}}
}

._tablet {
  .img-selection {
    .list-items {
      flex-direction: column;
      li {
        width: 90%;
      }
    }
  }
  //heading Topic font
  .header-div {h1{font-size: 26px !important;}}
}

/* its for Editor text alignment*/
// .rdw-center-aligned-block * {
//   text-align: center !important;
// }

.public-DraftStyleDefault-ltr {
  text-align: inherit !important;
}
.mt10 {
  margin-top: 10px;
}

.close {
  left: 0 !important;
}
.display-none {
   div{display: none;}
}

/* To rotate the icon */
.expand-tree-big {
  img {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
    -webkit-transition-duration: 1s;
    -moz-transition-duration: 1s;
    -o-transition-duration: 1s;
    transition-duration: 1s;
  }
}

.pt0 {
  padding-top: 0 !important;
}

.end-attest{
  .end-attest_inner{
    max-height:70%;
    min-height: 20%;
    .common-modal-header{
      h2{
        padding-left: 0 !important;
      }
    }
  }
}

.wrapper{
  .attestation-model_inner{
    @media screen and (width: 1366px) {
      min-height:40% !important;
      .image-gallery-display-btn {
        top: -7px !important;
        right: 6px !important;
        svg{
        background-color: transparent !important;
      }}
    }
  }
}

// Globe styles are used in contents only
.content-transition{
  .MuiButton-label, .MuiButton-root, .nav-btn {
    font-size: calc(var(--global-font-size) - 6px) !important;
    @include for-desktop-up{
      font-size: calc(var(--global-font-size) - 2px) !important;
    }
    @include  for-phone-only{
      font-size:var(--global-font-size)!important;
    }
  }
  .MuiButton-root,.MuiTypography-body1 {
    font-family: var(--global-font-family) !important;
    font-size: 14;
    // text-transform: capitalize !important;
  }
}
body, #root {
  font-family: var(--global-font-family) !important;
}
caption{
  color: #000;
}
@mixin abStyle($font,$color){
    font-size: $font!important;
    color: $color!important;
    font-family: var(--global-font-family-bold) !important;
}
.main-layout-container .markup-render,.common-model-cnt{
  h1{ @include abStyle(36px,#070D59); }
  h2{ @include abStyle(30px,#1E3C88); }
  h3{ @include abStyle(24px,#5993D4); }
  h4{ @include abStyle(36px,#14005A); }
  h5{ @include abStyle(30px,#03337C); }
  h6{ @include abStyle(26px,#1D82AD);}
}

//editor font size global is important
.gfs {
  .DraftEditor-editorContainer {
   span,p,li,ul{
     font-size: var(--global-font-size) !important;
   }
  }
 }
 .lrn-mode{
  .highlighter{
    visibility: hidden;
  }
 }

// Course completion popup
.crc-completion{
    display: flex;
    justify-content: center;
    height: 100%;
    align-items: center;
    text-align: center;
    flex-direction: column;
    min-height: 130px;
  .success {
    font-size: 59px;
    margin: 3px;
    color: var(--primary-color-1);
  }
}
