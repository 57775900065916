@import '../../theme/mixins.scss';
section[name=Accordion] {
        .MuiAccordionSummary-content {
            label.MuiTypography-root {
                font-size: var(--global-heading-font-size) !important;
                font-weight: 500;
                font-family: var(--global-font-family-medium) !important
            }
        }
        .acc-title{
            white-space: pre-wrap;
        }
        .MuiAccordion-rounded {
            border-radius: 0
        }
        .MuiAccordion-root {
            box-shadow: 0 2px 16px 4px RGB(40 44 63 / 5%);
            &.Mui-expanded {
                margin: 0;
            }
            .makeStyles-heading-7 {
                font-size: 1.7rem;
                font-weight: 600;
            }
            .MuiAccordionDetails-root {
                .MuiTypography-body1 {
                    font-size: var(--global-font-size);
                    line-height:var(--global-line-height);
                    font-family: unset;

                }
            }
            .MuiIconButton-root {
                padding:5px;
                .ac_visited {
                    color: var(--primary-color-1) !important;
                    transition: color 0.3s ease-in-out;
                }
                .MuiIconButton-label {
                    color: var(--bs-yellow);
                    .MuiSvgIcon-root {
                        font-size: 3.5rem;
                    }
                }
            }
        }
        @include for-tablet-up {
            margin:25px 0;
        }
}
.accordion-image{
    border-radius:4px;
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    box-sizing: content-box;
    .img{
        position: relative;
        .layer{
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            @include switch-direction-rtl(left,0);
        }
    }
    img{
        max-width: 100%;
        max-height: 400px;
    }
}
